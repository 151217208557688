import React, { useState } from "react"
import cx from 'classnames'
import { AdjustmentsIcon, ChipIcon, PresentationChartLineIcon, RefreshIcon, SparklesIcon, TagIcon } from "@heroicons/react/outline"
import { Typography } from "antd"

const { Title } = Typography

const FeatureLine = ({ label, icon, active, onSelect }) => {
  const Icon = icon

  return (
    <button onClick={onSelect} className={cx('flex flex-row items-center w-full p-3 bg-white text-white rounded-lg cursor-pointer my-3', {
      'bg-opacity-40': active,
      'bg-opacity-0': !active,
      'hover:bg-opacity-20': !active,
    })}>
      <div className='p-2 flex items-center justify-center rounded-lg bg-light-success mr-3'>
        <Icon className='h-6 w-6 text-success' />
      </div>
      <div className='text-sm text-left'>{label}</div>
    </button>
  )
}

const features = [
  {
    label: <div>Synchronisez <span className='font-bold'>tous</span> vos comptes bancaires</div>,
    icon: RefreshIcon,
    imageSrc: 'https://rocketchart-public.s3.eu-west-3.amazonaws.com/website/images/step-1.gif'
  },
  {
    label: <div>Créez vos <span className='font-bold'>propres</span> catégories et sous-catégories de classification</div>,
    icon: TagIcon,
    imageSrc: 'https://rocketchart-public.s3.eu-west-3.amazonaws.com/website/images/step-2.gif'
  },
  {
    label: <div>Catégorisez <span className='font-bold'>automatiquement</span> vos transactions avec notre algorithme</div>,
    icon: ChipIcon,
    imageSrc: 'https://rocketchart-public.s3.eu-west-3.amazonaws.com/website/images/step-4.gif'
  },
  {
    label: <div>Établissez <span className='font-bold'>simplement</span> votre budget prévisionnel</div>,
    icon: AdjustmentsIcon,
    imageSrc: 'https://rocketchart-public.s3.eu-west-3.amazonaws.com/website/images/step-3.gif'
  },
  {
    label: <div>Contrôlez en <span className='font-bold'>1 clic</span> vos performances Budget vs Réalisé</div>,
    icon: SparklesIcon,
    imageSrc: 'https://rocketchart-public.s3.eu-west-3.amazonaws.com/website/images/step-5.gif'
  },
  {
    label: <div>Importez <span className='font-bold'>en temps réel</span> vos factures depuis Sellsy</div>,
    icon: RefreshIcon,
    imageSrc: 'https://rocketchart-public.s3.eu-west-3.amazonaws.com/website/images/step-8.gif'
  },
  {
    label: <div>Visualisez la répartition <span className='font-bold'>précise</span> de vos dépenses et de vos revenus</div>,
    icon: PresentationChartLineIcon,
    imageSrc: 'https://rocketchart-public.s3.eu-west-3.amazonaws.com/website/images/step-7.gif'
  }
]

const BetaTestersLogos = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <div className='relative w-full flex flex-col items-center bg-primary py-12 px-2 mt-8 overflow-hidden'>
      <svg
        className="absolute -top-48 -right-52 transform rotate-12 z-0"
        width={404}
        height={404}
        fill="none"
        viewBox="0 0 404 404"
        role="img"
      >
        <title id="svg-workcation">Workcation</title>
        <defs>
          <pattern
            id="pattern-hdiw-1"
            x={0}
            y={0}
            width={56}
            height={56}
            patternUnits="userSpaceOnUse"
          >
            <circle x={0} y={0} cx="6" cy="6" r="6" className="text-light-primary opacity-50" fill="currentColor" />
          </pattern>
        </defs>
        <rect width={404} height={404} fill="url(#pattern-hdiw-1)" />
      </svg>

      <svg
        className="absolute -bottom-52 md:-bottom-10 -left-52 md:-left-32 transform rotate-12 z-0"
        width={404}
        height={404}
        fill="none"
        viewBox="0 0 404 404"
        role="img"
      >
        <title id="svg-workcation">Workcation</title>
        <defs>
          <pattern
            id="pattern-hdiw-2"
            x={0}
            y={0}
            width={104}
            height={104}
            patternUnits="userSpaceOnUse"
          >
            <circle x={0} y={0} cx="40" cy="40" r="40" className="text-light-primary opacity-50" stroke="currentColor" />
          </pattern>
        </defs>
        <rect width={404} height={404} fill="url(#pattern-hdiw-2)" />
      </svg>


      <Title level={2} className='text-white z-10' id='comment'>Comment ça marche ?</Title>

      <div className='content-container flex flex-col md:flex-row w-full mt-4 text-base z-10'>
        <div className='w-full md:w-1/2 mr-4 mt-3 rounded-xl shadow-lg' style={{ height: 'max-content' }}>
          <div className='relative h-8 w-full bg-gray-100 rounded-t-xl'>
            <div className='absolute top-3 left-4 h-2.5 w-2.5 bg-error rounded-full' />
            <div className='absolute top-3 left-8 h-2.5 w-2.5 bg-yellow-400 rounded-full' />
            <div className='absolute top-3 left-12 h-2.5 w-2.5 bg-success rounded-full' />
          </div>
          <img loading="lazy" src={features[selectedIndex].imageSrc} alt="Démonstration fonctionnement" className='rounded-b-xl w-full' />
        </div>

        <div className='w-full md:w-1/2'>
          {features.map((feature, index) => {
            return <FeatureLine key={index} {...feature} active={index === selectedIndex} onSelect={() => setSelectedIndex(index)} />
          })}
        </div>
      </div>
    </div>
  )
}

export default BetaTestersLogos
