import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Row, Col, Typography } from 'antd'

import Head from '../components/head'
import Layout from '../components/layout'
import BetaTestersLogosHeader from '../components/beta-testers-logos-header'
import BetaTestersLogos from '../components/beta-testers-logos'
import HowDoesItWorks from '../components/how-does-it-works'
import dashedLane from '../images/dashed-lane.svg'

import CtaRow from '../components/cta-row'
// import IntegrationsLogos from "../components/integrations-logos"
// import hypothesesData1 from '../data/hypotheses_chart_1.json'
// import hypothesesData2 from '../data/hypotheses_chart_2.json'
import { CheckIcon } from '@heroicons/react/solid'
import Letter from '../components/letter'
import Testimonials from '../components/testimonials'
import StoreReviews from '../components/store-reviews'

// import HypothesesWithChart from '../components/hypotheses/hypotheses-with-chart'

const { Title, Paragraph } = Typography

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "rocketchart-overview-dashboard-v2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      videoImage: file(relativePath: { eq: "rocketchart-dashboard-for-videos.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      banksynchroImage: file(relativePath: { eq: "rocketchart-connexion-bancaire.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      budgetImage: file(relativePath: { eq: "rocketchart-budget-previsionnel.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      depensesImage: file(relativePath: { eq: "rocketchart-depenses.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      rgpd: file(relativePath: { eq: "rocketchart-rgpd-comply.png" }) {
        childImageSharp {
          gatsbyImageData(height: 50)
        }
      }
      dataServers: file(relativePath: { eq: "rocketchart-data-servers.png" }) {
        childImageSharp {
          gatsbyImageData(height: 50)
        }
      }
      dataEncryption: file(relativePath: { eq: "rocketchart-data-encryption.png" }) {
        childImageSharp {
          gatsbyImageData(height: 50)
        }
      }
      arrowOneImage: file(relativePath: { eq: "arrow-down-1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowTwoImage: file(relativePath: { eq: "arrow-down-2.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowThreeImage: file(relativePath: { eq: "arrow-down-3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
    }
  `)

  const coverImage = 'https://i.imgur.com/dAwwW27.png'

  return (
    <Layout>
      <Head
        title="Logiciel de gestion de trésorerie pour TPE et PME"
        meta={[
          {
            property: 'og:image',
            content: coverImage
          },
          {
            name: 'twitter:image',
            content: coverImage
          }
        ]}
      />
      {/* bg-gradient-to-r from-primary via-indigo-500 to-indigo-600 */}

      <div className='content-container space-around-row flex flex-col lg:flex-row items-center lg:space-x-8'>
        <div className='w-full lg:w-5/12 flex flex-col items-center lg:items-start'>
          <Title>Oubliez les Excel laborieux pour gérer et anticiper votre trésorerie</Title>
          <Paragraph>Visualisez votre trésorerie en temps réel, automatisez votre suivi, réalisez des prévisions fiables et prenez les meilleures décisions pour l’avenir de votre entreprise.</Paragraph>
          <Paragraph className='font-bold text-lg'>Setup en 10min. Démarrez aujourd’hui !</Paragraph>

          <CtaRow />

          <div className="mt-8 flex flex-col items-center lg:items-start">
            <div className='text-gray-500'>Ils gèrent déjà leur trésorerie avec RocketChart by Sellsy</div>
            <div className='w-full max-w-xs mt-4'>
              <BetaTestersLogosHeader />
            </div>
          </div>

          <div className="mt-8 mb-8 lg:mb-0">
            <StoreReviews />
          </div>
        </div>

        <div className='w-full lg:w-7/12'>
          <GatsbyImage image={data.mainImage.childImageSharp.gatsbyImageData} alt="Logiciel de suivi de trésorerie automatisé en temps réel" className="rounded-image" />
        </div>
      </div>

      {/*
      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle" style={{ marginRight: 0, marginLeft: 0 }}>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 10, offset: 0, order: 0 }}>

        </Col>

        <Col xs={{ span: 16, offset: 4, order: 0 }} lg={{ span: 14, offset: 0, order: 1 }} justify="center">
        </Col>
      </Row> */}

      <Row className="content-container space-around-row" type="flex" justify="center">
        <Col xs={24} lg={16} align='center'>
          <Title level={3}>
            Regardons les choses en face:
            <br />
            suivre et anticiper votre trésorerie, c’est la <span className='rc-highlight-text-red'>galère.</span>
          </Title>
        </Col>

        <Col xs={24} lg={12} className='relative mt-4' align='center'>
          <div className='hidden lg:flex flex-col absolute -left-32 -top-16 '>
            <img width="100px" src={dashedLane} alt="dashed-lane" className='transform rotate-180 -mb-4' />
            <img width="100px" src={dashedLane} alt="dashed-lane" className='transform -rotate-90' />
          </div>
          <div className='hidden lg:flex flex-col absolute -right-32 -top-16'>
            <img width="100px" src={dashedLane} alt="dashed-lane" className='transform rotate-90 -mb-4' />
            <img width="100px" src={dashedLane} alt="dashed-lane" />
          </div>
          <Paragraph>
            Excel est <span className='underline'>laborieux</span>, vos outils ne sont <span className='underline'>pas inter-connectés</span>, vous <span className='underline'>perdez</span> du temps, votre suivi manuel n’est <span className='underline'>jamais à jour</span>, le <span className='underline'>risque d’erreur</span> est élevé et les sites des banques sont (presque) tous <span className='underline'>horribles</span> à utiliser.
            <br />
            <br />
            Bref, vous <span className='underline'>manquez</span> de visibilité et <span className='underline'>avez mieux à faire</span> que de copier-coller des données dans un tableau Excel.
          </Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" type="flex" justify="center">
        <Col xs={24} lg={16} align='center' style={{ marginTop: '20px', marginBottom: '40px' }}>
          <Title level={3}>
            Découvrez en 3 min comment vous <span className='rc-highlight-text-green'>simplifier la vie.</span>
          </Title>
        </Col>
        <Col xs={24} lg={16}>
          <GatsbyImage image={data.videoImage.childImageSharp.gatsbyImageData} alt="Discover RocketChart in video" />
        </Col>
      </Row>

      <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="rc-blob-container">
          <svg
            width="1700"
            height="1800"
            viewBox="0 0 800 800"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute', left: '-500px', top: '-150px', zIndex: '0', opacity: 0.2 }}
            className="rc-blob"
          >
            <g transform="translate(300,300)">
              <path d="M145.4,-109.8C192.9,-56.5,238.8,4.2,231.9,60.4C225.1,116.6,165.5,168.2,105,184.4C44.5,200.6,-16.8,181.4,-76.5,154.4C-136.2,127.3,-194.4,92.5,-200.5,50.2C-206.6,7.9,-160.8,-41.9,-118.7,-94C-76.6,-146.1,-38.3,-200.6,5.3,-204.8C49,-209.1,98,-163.1,145.4,-109.8Z" fill="#FFB4BC" />
            </g>
          </svg>

          <svg
            width="1800"
            height="1300"
            viewBox="0 0 800 800"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: 'absolute', right: '-800px', top: '1000px', zIndex: '0', opacity: 0.2 }}
            className="rc-blob"
          >
            <g transform="translate(300,300)">
              <path d="M137.1,-223.4C170,-192.1,183.7,-141.1,205.6,-93.7C227.6,-46.3,257.8,-2.4,233.2,19.8C208.7,42,129.3,42.6,94.5,81C59.8,119.3,69.8,195.4,49.4,229.2C29.1,262.9,-21.5,254.3,-61.5,232.1C-101.5,210,-130.9,174.4,-138.5,136.3C-146.1,98.2,-131.9,57.6,-136.5,20.2C-141,-17.2,-164.2,-51.4,-154,-68.9C-143.8,-86.5,-100.3,-87.4,-69.1,-119.5C-37.8,-151.6,-18.9,-214.8,16.6,-240.6C52.1,-266.4,104.2,-254.7,137.1,-223.4Z" fill="#4DAD71" />
            </g>
          </svg>
        </div>

        <Row className="content-container space-around-row" type="flex" justify="center">
          <Col xs={24} md={16}>
            <div align='center'>
              <GatsbyImage image={data.arrowTwoImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 2" />
            </div>
          </Col>
          <Col span={24} align='center' style={{ marginTop: '20px' }}>
            <Title level={2} id='fonctionnalites'>
              Maîtrisez votre trésorerie.
              <br />
              Prenez les bonnes décisions.
              <br />
              Ne perdez plus votre temps.
            </Title>
          </Col>
        </Row>

        <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
          <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
            <GatsbyImage image={data.banksynchroImage.childImageSharp.gatsbyImageData} alt="Connectez vos comptes bancaires pour gérer vos budgets en temps réel." />
          </Col>
          <Col xs={24} lg={12}>
            <Title level={3}><span className='rc-highlight-text'>Gagnez du temps</span> avec un suivi de trésorerie automatisé en temps réel</Title>

            <div>
              <div className='flex flex-row items-center mb-2'>
                <div className='rounded-full p-0.5 bg-light-success mr-2 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
                <div>Connectez tous vos comptes bancaires sur une seule interface </div>
              </div>

              <div className='flex flex-row items-center mb-2'>
                <div className='rounded-full p-0.5 bg-light-success mr-2 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
                <div>Créez vos catégories personnalisées pour classer vos flux</div>
              </div>

              <div className='flex flex-row items-center mb-2'>
                <div className='rounded-full p-0.5 bg-light-success mr-2 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
                <div>Suivez l’évolution de votre trésorerie consolidée au jour le jour</div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
          <Col xs={24} lg={12}>
            <Title level={3}><span className='rc-highlight-text'>Prenez les bonnes décisions</span> avec des prévisions de trésorerie fiables</Title>

            <div>
              <div className='flex flex-row items-center mb-2'>
                <div className='rounded-full p-0.5 bg-light-success mr-2 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
                <div>Établissez facilement votre budget prévisionnel à 3, 6 ou 12 mois</div>
              </div>

              <div className='flex flex-row items-center mb-2'>
                <div className='rounded-full p-0.5 bg-light-success mr-2 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
                <div>Synchronisez en temps réel vos factures depuis Sellsy</div>
              </div>

              <div className='flex flex-row items-center mb-2'>
                <div className='rounded-full p-0.5 bg-light-success mr-2 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
                <div>Simulez vos décisions business avec le multi-scénarios</div>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <GatsbyImage image={data.budgetImage.childImageSharp.gatsbyImageData} alt="Établissez simplement vos budgets prévisionnels" />
          </Col>
        </Row>

        <Row className="content-container space-around-row" gutter={40} type="flex" align="middle" style={{ marginBottom: '80px' }}>
          <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
            <GatsbyImage image={data.depensesImage.childImageSharp.gatsbyImageData} alt="Réduisez vos dépenses d'entreprise" />
          </Col>
          <Col xs={24} lg={12}>
            <Title level={3}><span className='rc-highlight-text'>Réduisez vos coûts</span> avec une maîtrise précise de vos dépenses</Title>

            <div>
              <div className='flex flex-row items-center mb-2'>
                <div className='rounded-full p-0.5 bg-light-success mr-2 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
                <div>Visualisez et contrôlez avec précision toutes vos dépenses </div>
              </div>

              <div className='flex flex-row items-center mb-2'>
                <div className='rounded-full p-0.5 bg-light-success mr-2 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
                <div>Comparez l’écart entre vos budgets prévisionnels et la réalité</div>
              </div>

              <div className='flex flex-row items-center mb-2'>
                <div className='rounded-full p-0.5 bg-light-success mr-2 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
                <div>Optimisez vos budgets pour augmenter votre rentabilité</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {/* COMMENT ÇA MARCHE */}
      <HowDoesItWorks />

      {/* HYPOTHESES */}
      {/* <Row className="content-container mt-32" type="flex" justify="center">
        <Col xs={24} lg={12}>
          <Title level={2} className='text-center'>Prenez les <span className='rc-highlight-text'>bonnes décisions</span> avec des prévisions de trésorerie fiables et automatisées</Title>
        </Col>
      </Row> */}
      {/* <HypothesesWithChart jsonData={hypothesesData1} className="content-container mt-8" /> */}
      {/*
      <span className='border-2 border-dashed rounded border-gray-200 w-full lg:w-96 my-16' />

      <Row className="content-container" type="flex" justify="center">
        <Col xs={24} lg={12}>
          <Title level={2} className='text-center'>Identifiez vos meilleures <span className='rc-highlight-text'>opportunités</span> de développement grâce aux scénarios prévisionnels</Title>
        </Col>
      </Row>
      <HypothesesWithChart jsonData={hypothesesData2} inverse largeChart className="content-container mt-8" />

      <span className='border-2 border-dashed rounded border-gray-200 w-full lg:w-96 my-16' /> */}

      {/* TEMOIGNAGES */}
      <Row className="content-container space-around-row" type="flex" justify="center">
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col span={24} align='center' style={{ marginTop: '20px' }}>
          <Title level={3}>
            Ils gèrent déjà leur trésorerie
            <br />
            avec RocketChart
          </Title>
        </Col>
      </Row>

      <Testimonials />

      <Row className="content-container space-around-row" type="flex" justify="center">
        <Col xs={24} lg={20} align='center' style={{ marginTop: '0px', marginBottom: '20px' }}>
          <div className='w-full max-w-md lg:max-w-none'>
            <BetaTestersLogos />
          </div>
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: '100px', marginBottom: '0px' }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowOneImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col span={24} align='center' className='mt-5 mb-24 flex flex-col items-center'>
          <Title level={2} className='mb-8'>Démarrez maintenant votre essai gratuit</Title>
          <CtaRow />
          <div className="mt-4">
            <StoreReviews />
          </div>
        </Col>
      </Row>

      <Letter />

      {/* <Row className="content-container" type="flex" justify="center" style={{ marginTop: "50px", marginBottom: "50px" }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowOneImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down" />
          </div>
        </Col>
        <Col span={12} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Intégrez vos outils de facturation, ERP et logiciels de comptabilité</Title>
          <Paragraph>Synchronisez en temps réel vos factures dans RocketChart pour <span className='rc-highlight-text'>générer automatiquement votre prévisionnel</span> de trésorerie et <span className='rc-highlight-text'>automatiser le rapprochement</span> banque / facture.</Paragraph>
        </Col>
        <Col span={24} align='center' style={{ marginTop: '20px' }}>
          <IntegrationsLogos />
        </Col>
      </Row> */}

      {/* <span className='border-2 border-dashed rounded border-gray-200 w-full lg:w-96 my-16' /> */}
      {/* <br /> */}

      {/* <iframe loading="lazy" width="560" height="315" className='max-w-full' title="Bienvenue !" src="https://www.youtube.com/embed/7JzhQHwnWLI?rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}

      <span className='border-2 border-dashed rounded border-gray-200 w-full lg:w-96 my-16' />

      <Row className="content-container" gutter={40} type="flex" style={{ marginBottom: '60px' }}>
        <Col xs={24} lg={8}>
          <Row>
            <Col xs={20} lg={24}>
              <Title level={3} className='flex flex-row items-center'>
                <GatsbyImage image={data.rgpd.childImageSharp.gatsbyImageData} alt="Rocketchart respecte la RGPD" className='mr-4' />
                Conforme RGPD
              </Title>
              <Paragraph>Notre politique de confidentialité et de traitement des données personnelles est conforme au Règlement Général sur la Protection des Données.</Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={8}>
          <Row>
            <Col xs={20} lg={24}>
              <Title level={3} className='flex flex-row items-center'>
                <GatsbyImage image={data.dataServers.childImageSharp.gatsbyImageData} alt="Hébergement certifié" className='mr-4' />
                Hébergement certifié
              </Title>
              <Paragraph>Vos données sont répliquées en temps réel dans plusieurs data-centers distincts en Europe, vous garantissant un accès permanent à vos datas.</Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={8}>
          <Row>

            <Col xs={20} lg={24}>
              <Title level={3} className='flex flex-row items-center'>
                <GatsbyImage image={data.dataEncryption.childImageSharp.gatsbyImageData} alt="Données cryptées" className='mr-4' />
                Données chiffrées
              </Title>
              <Paragraph>Vos accès sont sécurisés par différents mécanismes protégeant vos données à tout instant, que nous chiffrons en transit comme au repos dans nos bases.</Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>

    </Layout>
  )
}

export default IndexPage
