import React, { useCallback, useEffect, useRef, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import { PlayIcon } from '@heroicons/react/solid'
import cx from 'classnames'

export default function Letter ({ marcImage }) {
  const data = useStaticQuery(graphql`
    query {
      marcImage: file(relativePath: { eq: "rocketchart_marc_vanderstigel.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 300)
        }
      }
    }
  `)

  const audio = useRef(null)
  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    if (!audio.current) return

    audio.current.addEventListener('pause', () => setPlaying(false))
    audio.current.addEventListener('play', () => setPlaying(true))
  }, [audio, setPlaying])

  const playPause = useCallback(() => {
    if (!audio.current) return

    if (playing) audio.current.pause()
    else audio.current.play()
  }, [playing, audio])

  return (
    <div className='content-container flex flex-col items-center'>
      <div className='w-full md:w-1/2 flex flex-row justify-between items-center'>
        <button onClick={playPause} className='relative'>
          <GatsbyImage
            image={data.marcImage.childImageSharp.gatsbyImageData}
            alt="Marc Vanderstigel from RocketChart"
            className={cx('w-32 h-32 z-10 rounded-full shadow-lg bg-white', {
              'opacity-80': !playing,
              'opacity-100': playing
            })}
          />

          {(playing && (
            <div className='absolute top-0 w-32 h-32 flex items-center justify-center z-0'>
              <div className='asbolute w-24 h-24 bg-light-primary bg-opacity-75 rounded-full animate-ping-slow' />
              <div className='absolute w-24 h-24 bg-light-success bg-opacity-60 rounded-full animate-ping-slow-1' />
              <div className='absolute w-20 h-20 bg-light-error bg-opacity-60 rounded-full animate-ping-slow-2' />
            </div>
          )) || (
              <div className='absolute top-0 w-32 h-32 flex items-center justify-center z-10'>
                <PlayIcon className='h-10 w-10 text-white animate-pulse' />
              </div>
          )}

        </button>

        <audio
          ref={audio}
          controls
          src="https://rocketchart-public.s3.eu-west-3.amazonaws.com/website/marc-1.2x.mp3"
          className='flex-grow ml-4'
        >
          <track default
            kind="captions"
            srcLang="fr"
          />
        </audio>
      </div>

      <div className='w-full md:w-1/2 mb-4 text-justify font-hand text-lg mt-4'>
        Salut, moi c’est Marc, et avec Elie et Philippe on a décidé de monter une boite en 2019. Philippe c’est mon frère, et Elie c’est un ancien collègue de Philippe.
        <br />
        <br />
        On a toujours voulu créer une boite avec du sens. Nous, notre truc, c’était de monter une entreprise à taille humaine, sur fonds propre, pour servir des clients qui comme nous sont entrepreneurs.
        <br />
        <br />
        Alors si tu lis ces lignes, sache qu’on est très heureux que tu sois là. On a une mission : que le pilotage financier de ton entreprise soit simple !
        <br />
        <br />
        Plus d‘Excel fastidieux, plus d'erreurs humaines, plus besoin de passer 4 heures par semaine à faire de la saisie manuelle.
        <br />
        <br />
        On a créé RocketChart avec un objectif en tête : que tous les jours, tu regrettes de ne pas nous avoir trouvé plus tôt.
        <br />
        <br />
        On veut que le suivi financier de ton entreprise soit simple, si simple qu’en fermant RocketChart tous les jours tu aies le sourire.
        <br />
        <br />
        Alors si tu penses qu’on ne s’est pas croisé par hasard, c’est que tu es au bon endroit.
        <br />
        <br />
        On t’envoie toute l’énergie positive du monde.
        <br />
        <br />
        <div className='text-right'>Marc, Elie et Philippe</div>
      </div>
    </div>
  )
}
