import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

const BetaTestersLogosHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      pinpoImage: file(relativePath: { eq: "pinpo-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      champercheImage: file(relativePath: { eq: "champerche-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      hubvisoryImage: file(relativePath: { eq: "hubvisory-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      meilleursBiensImage: file(relativePath: { eq: "meilleursbiens-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      studiomaticImage: file(relativePath: { eq: "studiomatic-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      welybImage: file(relativePath: { eq: "welyb-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <div className='flex-row grid grid-flow-row grid-cols-3  gap-x-8 gap-y-4'>
      <div className='filter grayscale hover:grayscale-0'>
        <GatsbyImage image={data.pinpoImage.childImageSharp.gatsbyImageData} alt="Pinpo logo" />
      </div>
      <div className='filter grayscale hover:grayscale-0'>
        <GatsbyImage image={data.champercheImage.childImageSharp.gatsbyImageData} alt="Champerché logo" />
      </div>
      <div className='filter grayscale hover:grayscale-0'>
        <a href="https://www.welyb.fr"><GatsbyImage image={data.welybImage.childImageSharp.gatsbyImageData} alt="Welyb logo" /></a>
      </div>
      <div className='filter grayscale hover:grayscale-0'>
        <a href="https://hubvisory.com"><GatsbyImage image={data.hubvisoryImage.childImageSharp.gatsbyImageData} alt="Hubvisory logo" /></a>
      </div>
      <div className='filter grayscale hover:grayscale-0'>
        <GatsbyImage image={data.meilleursBiensImage.childImageSharp.gatsbyImageData} alt="MeilleursBiens logo" />
      </div>
      <div className='filter grayscale hover:grayscale-0'>
        <GatsbyImage image={data.studiomaticImage.childImageSharp.gatsbyImageData} alt="Studiomatic logo" />
      </div>
    </div>
  )
}

export default BetaTestersLogosHeader